import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/ClaimAirDrop1.json' // 合约abi

import Web3Obj from '@/utils/web3.js'

const provider = config.provider

let web3
try {
  web3 = web3 = new Web3(window.ethereum)
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider(provider))
}
const currentAccount = store.state.myAcount // 当前钱包账户
const contractInstance = new web3.eth.Contract(contractAbi, config.claimAirDrop1)

/**
 * 获取池子数
 */
const getRoundLength = async () => {
  try {
    var resp = await contractInstance.methods
      .getRoundLength()
      .call({ })
    return Web3Obj.successResult(resp)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}

/**
 * 获取用户在池子列表的数据
 */
const pending = async (star, end, account) => {
  try {
    var resp = await contractInstance.methods
      .pending(account || store.state.myAcount, star, end)
      .call({ })
    return Web3Obj.successResult(resp)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}
/**
 * 提取
 */
const withdraw = async (rounds) => {
  await Web3Obj.connectWallet()
  try {
    const resp = await contractInstance.methods
      .withdraw(rounds)
      .send({ from: currentAccount || store.state.myAcount, ...Web3Obj.gasPriceObj })
    return Web3Obj.successResult(resp)
  } catch (error) {
    return Web3Obj.failResult(error)
  }
}

export default {
  getRoundLength,
  pending,
  withdraw
}
