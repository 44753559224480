import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/MaiAirDrop.json' // 业务合约
import web3Obj from '../utils/web3'
import store from '@/store'

const contractAddress = config.maiAirDrop
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)

/**
现存矩阵点位空投规则

1.根据现存元点空投数据获取用户“总开启矩阵点位数”数据，从srcMetas中获取

2.可领取空投收益从srcPending获取

3.领取空投奖励调用srwithdraw
 */

// 总开启矩阵点位数
const srcMetas = async (account) => {
  try {
    const resp = await contractInstance.methods
      .srcMetas(account || store.state.myAcount)
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}
/**
 * 可领取空投
 * @return
 */
const srcPending = async (account) => {
  try {
    var balance = await contractInstance.methods
      .srcPending(account || store.state.myAcount)
      .call({})
    balance = web3Obj.fromWei(balance)
    return web3Obj.successResult(balance)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 领取空投
 */
const srcWithdraw = async (amount) => {
  amount = web3Obj.toWei(amount)
  try {
    const resp = await contractInstance.methods
      .srcWithdraw(amount)
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 新增矩阵点位空投规则

1.首先查询用户地址是否存在新增矩阵点位用户列表，是则判断后续规则，否则提示用户激活空投。

1.1激活判断条件从claims判断，true为已激活，空或false为未激活

2.激活空投调用矩阵点位空投的激活方法，激活功能调用newClaim

3.激活成功后的用户新增矩阵点位数从newpending-count获取

4.可领取空投收益从newPending-amount获取

5.领取空投奖励调用newwithdraw

6.未激活时，按钮显示“激活空投”，否则显示”领取空投”

7.newwithdrawswitch为false时，“领取空投”按钮置灰显示，为true时则高亮可点击
 */

// claims
const claims = async (account) => {
  try {
    const resp = await contractInstance.methods
      .claims(account || store.state.myAcount)
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

const newClaim = async () => {
  try {
    const resp = await contractInstance.methods
      .newClaim()
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

const newPending = async (account) => {
  try {
    const resp = await contractInstance.methods
      .newPending(account || store.state.myAcount)
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

const newWithdrawSwitch = async () => {
  try {
    const resp = await contractInstance.methods
      .newWithdrawSwitch()
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

const newWithdraw = async () => {
  try {
    const resp = await contractInstance.methods
      .newWithdraw()
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}
export default {
  srcMetas,
  srcPending,
  srcWithdraw,
  claims,
  newClaim,
  newPending,
  newWithdrawSwitch,
  newWithdraw
}
