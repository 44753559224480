import request from '../utils/request'
import store from '@/store'

// 获取mai点灯奖励
export const queryMaiReward = () => {
  return request('GET', `/meta/getEstimatedAward/${store.state.myAcount}`, '', false)
}

// 获取mai空投数据
export const queryMaiDonateInfo = () => {
  return request('GET', '/meta/getMaiAirdropInfo', '', false, 'https://metabills.finance')
}
