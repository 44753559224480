import axios from 'axios'
import store from '@/store'
import config from '@/config/app.config'
import { Toast } from 'vant'
import i18n from '../main'
const requestMsg = i18n.t('requestMsg')

// import router from '../router'
// axios.defaults.withCredentials = true

axios.defaults.crossDomain = true
axios.defaults.timeout = 8000
// console.log(requestMsg)
// const hello = 'hello'
// console.log(requestMsg[hello])

const request = (method, url, params, needToken = true, baseUrl = '', signData, noLoading, loadingIconType, headerContentType = 'application/json', showErrorMessage = true) => {
  console.log(baseUrl)
  const headers = {
    'Content-Type': headerContentType
  }
  headers.lang = store.state.lang || 'ZH'
  if (needToken) {
    headers.message = signData.message
    headers.signature = signData.signature
    headers.address = signData.address
  }
  if (noLoading) {
  } else {
    // 显示全局加载loading
    store.state.isLoading = true
  }
  if (loadingIconType) {
    store.state.loadingIconType = loadingIconType
  } else {
    store.state.loadingIconType = ''
  }
  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      baseURL: baseUrl || config.server,
      url,
      timeout: 15000,
      params: method === 'GET' || method === 'DELETE' ? params : null, // 是即将与请求一起发送的 URL 参数
      data: method === 'POST' || method === 'PUT' ? params : null // 是作为请求主体被发送的数据
    }).then(res => {
      // 全局加载样式隐藏
      setTimeout(() => {
        store.state.isLoading = false
      }, 500)
      Toast.clear()
      if (res.data.code === 422) {
        localStorage.removeItem('authorized')
        store.commit('SET_TOKEN', '')
        store.commit('SET_MY_ACCOUNT', '')
        resolve({ success: false, records: [], result: {}, total: 0, message: '登录已失效，请重新登录' })
      } else if (res.data.code === 200) {
        res.data.success = true
        resolve(res.data)
      } else if (res.data.code === 510) {
        // 标识没有账户
        store.commit('SET_NEED_CREATE_ACCOUNT', true)
        res.data.success = false
        resolve(res.data)
      } else {
        res.data.success = false
        const msg = requestMsg[res.data.message]
        Toast.fail(msg, 5)
        resolve(res.data)
      }
    }).catch(error => {
      Toast.clear()
      setTimeout(() => {
        store.state.isLoading = false
      }, 500)
      let messageText = ''
      if (error.response && error.response.data && error.response.data.message) {
        messageText = error.response.data.message
      } else {
        // messageText = error.response ? getErrorMessage(error.response.status) : 'Network exception'
        messageText = 'Network exception'
      }
      Toast.fail(requestMsg[messageText] || messageText, 5)
      // reject(error)
      resolve({ success: false, records: [], result: {}, total: 0, message: 'Network exception' })
    })
  })
}

export default request
