<template>
  <div class="aridrop-page">
    <!-- 矩阵点位领取空投 -->
    <div class="box">
      <div class="header">
        <div class="left">{{ $t('maiAirdrop.Addpoint') }}</div>
        <div class="new">NEW</div>
        <div class="right" @click="handleShowRule(3)">
          <img src="@/assets/help.png" class="help-img" />
        </div>
      </div>
      <div class="key-value _flex fs12">
        <div class="key">{{ $t('maiAirdrop.maiPool')}}：</div>
        <div class="value">{{ $gbUtils.formatBalance(reward3Pool)}}</div>
      </div>
      <div class="key-value _flex fs12">
        <div class="key">{{ $t('maiAirdrop.estimatedToday')}}：</div>
        <div class="value">{{ $gbUtils.formatBalance(todayAllocation) }}</div>
      </div>
      <div class="key-value _flex fs12">
        <div class="key">{{$t('maiAirdrop.curLightReward')}}：</div>
        <div class="value">{{  $gbUtils.formatBalance(lightReward) }} / {{ $t('maiAirdrop.once') }}</div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.Receiveairdrop') }}：</div>
        <div class="value">{{ $gbUtils.formatBalance(reward4) }}</div>
      </div>
      <VanButton class="btn btn1" :disabled="reward4 <= 0" :loading="claimloading4"
        @click="handleClaim4">{{ $t('maiAirdrop.ReceiveAirdrop')}}
      </VanButton>
    </div>
     <!-- 矩阵点位领取空投 -->
     <div class="box">
      <div class="header">
        <div class="left">{{ $t('maiAirdrop.Addpoint') }}</div>
        <div class="new">OLD</div>
        <div class="right" @click="handleShowRule(3)">
          <img src="@/assets/help.png" class="help-img" />
        </div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.Receiveairdrop') }}：</div>
        <div class="value">{{ $gbUtils.formatBalance(reward3) }}</div>
      </div>
      <VanButton class="btn btn1" :disabled="reward3 <= 0" :loading="claimloading3"
        @click="handleClaim3">{{ $t('maiAirdrop.ReceiveAirdrop')}}
      </VanButton>
    </div>
    <!-- 現存矩陣點位空投  -->
    <!-- <div class="box">
      <div class="header">
        <div class="left">{{ $t('maiAirdrop.ExistingAirdrop') }}</div>
        <div class="right" @click="handleShowRule(1)">
          <img src="@/assets/help.png" class="help-img" />
        </div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.TotalNumberOfOpen') }}</div>
        <div class="value">{{ totalPoint || 0 }}</div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.Receiveairdrop') }}</div>
        <div class="value">{{ $gbUtils.formatBalance(reward1) || 0 }}</div>
      </div>
      <VanButton class="btn" :disabled="reward1 <= 0" :loading="claimloading1" @click="handleClaim1">
        {{ $t('maiAirdrop.ReceiveAirdrop') }}</VanButton>
    </div> -->
    <!-- 新增矩阵点位空投 -->
    <!-- <div class="box">
      <div class="header">
        <div class="left"> {{ $t('maiAirdrop.Addpoint') }}</div>
        <div class="right" @click="handleShowRule(2)">
          <img src="@/assets/help.png" class="help-img" />
        </div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.Newpoints') }}</div>
        <div class="value">{{ addPoint || 0 }}</div>
      </div>
      <div class="key-value">
        <div class="key">{{ $t('maiAirdrop.Receiveairdrop') }}</div>
        <div class="value">{{ $gbUtils.formatBalance(reward2) || $t('maiAirdrop.Estimated') }}</div>
      </div>
      <VanButton class="btn btn1" :loading="activeLoading" @click="handleActive" v-if="!isActive">
        {{ $t('maiAirdrop.ActivateAirdrop') }}</VanButton>
      <VanButton class="btn btn1" :disabled="reward2 <= 0 || !withdrawSwitch" :loading="claimloading2"
        @click="handleClaim2" v-else>{{ withdrawSwitch? $t('maiAirdrop.ReceiveAirdrop'): $t('maiAirdrop.Notopened')}}
      </VanButton>
    </div> -->
    <IllustrateDialogVue v-if="ruleShow" :ruleContent="ruleContent" @close="() => { ruleShow = false }" />
  </div>
</template>

<script>
import contractMaiAirDrop from '../../contracts/contractMaiAirDrop'
import IllustrateDialogVue from './components/IllustrateDialog.vue'
import contractClaimAirDrop1 from '../../contracts/contractClaimAirDrop1'
import contractClaimAirDrop2 from '../../contracts/contractClaimAirDrop2'
import { queryMaiDonateInfo } from '../../services/maiReward'
import config from '../../config/app.config'

export default {
  name: '',
  components: { IllustrateDialogVue },
  data () {
    return {
      totalPoint: 0,
      reward1: 0,
      claimloading1: false,
      addPoint: 0,
      reward2: 0,
      claimloading2: false,
      isActive: false,
      activeLoading: false,
      withdrawSwitch: false,
      ruleShow: false,
      ruleContent: '',
      demoAccount: '',
      claimRounds: [],
      reward3: 0,
      claimloading3: false,
      reward3Pool: 0,
      todayAllocation: 0,
      lightReward: 0,
      reward4: 0,
      claimloading4: false
    }
  },
  methods: {
    async init () {
      // this.init1()
      // this.init2()
      this.init3()
      this.init4()
      this.initMaiReward()
    },
    handleShowRule (type) {
      this.ruleContent = type === 1 ? this.$t('maiAirdrop.ruleContent1')
        : type === 2 ? this.$t('maiAirdrop.ruleContent2') : this.$t('maiAirdrop.ruleContent3')
      this.ruleShow = true
    },
    init1 () {
      this.getTotal()
      this.getReward1()
    },
    async getTotal () {
      const resp = await contractMaiAirDrop.srcMetas(this.demoAccount)
      if (!resp.success) return
      this.totalPoint = resp.result
    },
    async getReward1 () {
      const resp = await contractMaiAirDrop.srcPending(this.demoAccount)
      if (!resp.success) return
      this.reward1 = resp.result
    },
    async handleClaim1 () {
      this.claimloading1 = true
      const resp = await contractMaiAirDrop.srcWithdraw(this.reward1)
      this.claimloading1 = false
      if (!resp.success) return
      this.init1()
    },
    async init2 () {
      await this.getIsActive()
      if (this.isActive) {
        this.getAdd()
        this.getSwitch()
      }
    },
    async getIsActive () {
      const resp = await contractMaiAirDrop.claims(this.demoAccount)
      if (!resp.success) return
      this.isActive = resp.result
    },
    async handleActive () {
      this.activeLoading = true
      const resp = await contractMaiAirDrop.newClaim()
      this.activeLoading = false
      if (!resp.success) return
      this.isActive = true
      this.init2()
    },
    async getAdd () {
      const resp = await contractMaiAirDrop.newPending(this.demoAccount)
      if (!resp.success) return
      this.addPoint = resp.result.count
      this.reward2 = this.$web3.fromWei(resp.result.amount) || 0
    },
    async getSwitch () {
      const resp = await contractMaiAirDrop.newWithdrawSwitch()
      if (!resp.success) return
      this.withdrawSwitch = resp.result
    },
    async handleClaim2 () {
      this.claimloading2 = true
      const resp = await contractMaiAirDrop.newWithdraw()
      this.claimloading2 = false
      if (!resp.success) return
      this.init2()
    },
    async init3 () {
      const resp = await contractClaimAirDrop1.getRoundLength()
      if (!resp.success) return
      this.getPending(resp.result)
    },
    async getPending (len) {
      const resp = await contractClaimAirDrop1.pending(0, len)
      if (!resp.success) return
      const amounts = resp.result.amounts
      const claims = resp.result.claims || []
      this.reward3 = 0
      this.claimRounds = []
      claims.forEach((item, index) => {
        if (!item) {
          this.claimRounds.push(index)
          this.reward3 += +this.$web3.fromWei(amounts[index])
        }
      })
    },
    async handleClaim3 () {
      this.claimloading3 = true
      const resp = await contractClaimAirDrop1.withdraw(this.claimRounds)
      this.claimloading3 = false
      if (!resp.success) return
      this.init3()
    },
    async init4 () {
      const resp = await contractClaimAirDrop2.getRoundLength()
      if (!resp.success) return
      this.getPending2(resp.result)
    },
    async getPending2 (len) {
      const resp = await contractClaimAirDrop2.pending(0, len)
      if (!resp.success) return
      const amounts = resp.result.amounts
      const claims = resp.result.claims || []
      this.reward3 = 0
      this.claimRounds = []
      claims.forEach((item, index) => {
        if (!item) {
          this.claimRounds.push(index)
          this.reward4 += +this.$web3.fromWei(amounts[index])
        }
      })
    },
    async handleClaim4 () {
      this.claimloading4 = true
      const resp = await contractClaimAirDrop2.withdraw(this.claimRounds)
      this.claimloading4 = false
      if (!resp.success) return
      this.init4()
    },
    async initMaiReward () {
      // this.getPoolBalance()
      this.getMaiReward()
    },
    async getPoolBalance () {
      const resp = await this.$web3.balanceOf(config.MAI, config.claimAirDropPool)
      if (!resp.success) return
      this.reward3Pool = resp.result || 0
    },
    async getMaiReward () {
      const resp = await queryMaiDonateInfo()
      console.log(resp)
      if (!resp.success) return
      this.reward3Pool = resp.result.totalAllocation || 0
      this.todayAllocation = resp.result.todayAllocation || 0
      this.lightReward = resp.result.currentReward || 0
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.aridrop-page {
  color: #fff;
  padding: 0 15px;

  .box {
    background: #252525;
    border-radius: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 12px 22px;
    margin: 20px 0;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        position: relative;
        height: 33px;
        margin-right: 10px;
        padding-left: 3px;
        font-size: 13px;
        font-weight: 500;
        background: linear-gradient(110deg,
            #ffad43 0%,
            #ff7c6b 14.990234375%,
            #fd5090 64.990234375%,
            #662bfc 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

      }

      .right {}
    }

    .help-img {
      width: 20px;
      height: 20px;
    }

    .key-value {
      text-align: center;

      .key {
        font-size: 16px;
        font-weight: bold;
        line-height: 2em;
        color: rgba(255, 255, 255, 0.6);
      }

      .value {
        font-size: 18px;
        font-weight: 500;
        line-height: 2em;
      }
    }

    .btn {
      width: 100%;
      height: 48px;
      line-height: 48px;
      background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
      border-radius: 24px;
      font-size: 14px;
      color: #ffffff;
      margin-top: 15px;
    }

    .btn1 {
      background: #6667ff;
    }
  }

  .new {
    padding: 5px 8px;
    background: linear-gradient(135deg, #FFAF66 0%, #FF6666 100%);
    box-shadow: 0px 0px 10px 0px rgba(255, 104, 104, 0.2);
    border-radius: 24px 24px 24px 24px;
    opacity: 1;
    font-size: 12px;
    font-weight: 500;
    color: #F5F5F5;
    margin-right: auto;
  }
}
._flex {
  display: flex;
  align-items: center;
}

</style>
